import styled from 'styled-components';

const IconList = styled.ul`
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		>* {
			display: flex;
			align-items: center;
			min-height: 23px;
			word-break: break-all;
		}

		+* {
			margin-top: ${props => props.inline ? 0 : '10px'};
		}
	}

	a {
		text-decoration: none;
		font-family: 'Avenir Medium', sans-serif;
	}

	svg {
		width: 20px;
		height: auto;
		margin-right: 16px;
		fill: currentColor;
	}

	${props => props.inline && `
		display: flex;
		justify-content: space-around;
	`};
`;

export default IconList
