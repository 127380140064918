import React from 'react';
import styled, { css } from 'styled-components';

import { getLinkProps } from '../utils/validation';

import Container from './Container';
import FadeEntry from './FadeEntry';
import _Section from './Section';
import Title from './Title';

const TextSections = ({ reverse, sections, oddStyles, compact }) => {

	if(!sections || !sections.length) return null;

	return(
		<Outer
			css={!compact ? `
				padding: 0!important;
			` : null}
		>
			{ sections.map(({ title, text, image, link, linkText }, i) => {
				const linkProps = getLinkProps(link);
				return (
          <Section
            key={`text-section-${i}`}
            reverse={reverse}
            css={i % 2 !== 0 ? oddStyles : null}
            compact={compact}
          >
            <Container>
              {text ? (
                <FadeEntry className="text-section__text">
                  <Title as={"h3"}>{title}</Title>

                  <div
                    dangerouslySetInnerHTML={{ __html: text }}
                    className="user-content"
                  />

                  {link ? <Button {...linkProps}>{linkText}</Button> : null}
                </FadeEntry>
              ) : null}

              {image ? (
                <FadeEntry className={"text-section__img"}>
                  <img src={image.source_url} alt={image.alt_text} />
                </FadeEntry>
              ) : null}
            </Container>
          </Section>
        );
			}) }
		</Outer>
	);
}

const Section = styled.div`
	${Container} {
		display: flex;
		justify-content: space-between;
	}
	
	.text-section {	
		&__text {
			width: 58%;
			font-size: ${({ theme }) => theme.fontSize.bodyLg};
			text-align: left;
			
			${Title} {
				margin-bottom: 20px;
				font-size: ${({ theme }) => theme.fontSize.titleLg};
			}
		}
		
		&__img {
			flex: 1;
			padding-left: 35px;
			
			img {
				margin-left: auto;
			}
		}
	}

	${({ compact }) => compact ? `
		+* {
			margin-top: 120px;

			@media (max-width: 767px) {
				margin-top: 60px;
			}
		}
	` : `
		padding: 120px 0;

		@media (max-width: 767px) {
			padding: 60px 0;
		}
	`}
	
	&:nth-of-type(${({ reverse }) => reverse ? 'odd' : 'even'}) {
		${Container} {
			flex-direction: row-reverse;
		}
		
		.text-section__img {
			padding: 0 35px 0 0;
			
			img {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}

	@media (max-width: 599px) {
		.text-section__text {
			width: 100%;
		}
		
		.text-section__img {
			display: none;
		}
	}
`;

const Outer = styled(_Section)``;

TextSections.defaultProps = {
	compact: true
}

export default TextSections;