import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import FadeEntry from './FadeEntry';

const Outer = styled.blockquote`
	padding: 80px 20px;
	text-align: center;
	font-size: ${({ theme }) => theme.fontSize.bodyLg};
	background: ${({ theme }) => theme.color.blue};
	color: ${({ theme }) => theme.color.white};
	
	.quote {
		&__quote {
			max-width: 750px;
			margin: 0 auto;
		}
	
		&__author {
			margin-top: 22px;
			
			strong {
				line-height: 150%;
			}
		}
	}
`;

const Quote = ({ quote, author }) => {
	
	if(!quote) return null;

	return (
		<Outer>
			<FadeEntry
				className={'quote__quote'}
			>
				<p dangerouslySetInnerHTML={{ __html: '&ldquo;' + quote + '&rdquo;' }} />
			</FadeEntry>

			<FadeEntry
				className={'quote__author'}
			>
				<strong dangerouslySetInnerHTML={{ __html: author }} />
			</FadeEntry>
		</Outer>
	)
}

Quote.propTypes = {
	quote: PropTypes.string,
	author: PropTypes.string,
}

export default Quote
