import React from 'react';
import styled from 'styled-components';

import { getLinkProps } from '../utils/validation';

import Button from './Button';
import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';

const TextColumns = ({ title, columns, cards }) => {

	if(!columns || !columns.length) return null;

	return(
		<Outer cards={cards}>
			<Container>
				{ title ? (
					<FadeEntry
						className={'text-columns__title'}
					>
						<Title
							as={'h3'}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
					</FadeEntry>
				) : null }

				<Inner>
					{ columns.map(({ title, text, link, linkText }) => {
						const linkProps = getLinkProps(link);

						return(
							<FadeEntry
								className={'text-columns__column'}
								key={`text-column-${title}`}
							>
								<Title
									as={'h5'}
									size={'titleSm'}
									dangerouslySetInnerHTML={{ __html: title }}
								/>
	
								<div dangerouslySetInnerHTML={{ __html: text }} />
	
								{ link ? (
									<Button 
										{...linkProps}
										size="small"
									>{ linkText }</Button>
								) : null }
							</FadeEntry>
						);
					}) }
				</Inner>
			</Container>
		</Outer>
	);
}

const Inner = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const Outer = styled(Section)`
	.text-columns__title {
		${Title} {
			margin-bottom: 43px;
			font-size: ${({ theme }) => theme.fontSize.titleLg};
		}
	}
	
	.text-columns__column {
		position: relative;
		width: ${({ cards }) => cards ? 'calc((100% / 3) - 48px / 3)' : 'calc(100% / 3)'};
		padding: ${({ cards }) => cards ? '40px 20px 30px' : '50px 20px 0'};
		background: ${({ cards }) => cards ? '#FFFFFF' : 'none'};
		box-shadow: ${({ cards }) => cards ? '0 5px 10px 0 rgba(0,0,0,0.10)' : 'none'};
		
		${Title} {
			margin-bottom: 10px;
		}
		
		>div {
			opacity: .8;

			+* {
				margin-top: 25px;
			}
		}
		
		&:before {
			content: '';
			position: absolute;
			top: ${({ cards }) => cards ? '-10px' : 0};
			left: 50%;
			transform: translateX(-50%);
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: ${({ theme }) => theme.color.brand};
		}
	}

	${props => props.cards && `
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 54%;
			height: 0;
			padding-bottom: 64%;
			background-image: url('/images/oval-left.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center left;
			z-index: -1;
		}

		@media (min-width: 768px) {
			.text-columns__column {
				&:nth-of-type(5n+1),
				&:nth-of-type(5n+2),
				&:nth-of-type(5n+3),
				&:nth-of-type(5n+4),
				&:nth-of-type(5n+5) {
					margin-top: 58px;
				}
	
				&:nth-of-type(5n+1),
				&:nth-of-type(5n+2),
				&:nth-of-type(5n+4) {
					margin-right: 24px;
				}
	
				&:nth-of-type(5n+4) {
					margin-left: 10%;
				}
	
				&:nth-of-type(5n+5) {
					margin-right: 10%;
				}
			}
		}
	`};
	
	@media (max-width: 767px) {
		.text-columns__column {
			width: 100%;
			
			+* {
				margin-top: 40px;
			}
		}
	}
`;

export default TextColumns;