import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { css } from 'styled-components';

import CallToAction from '../components/CallToAction';
import ContactBar from '../components/ContactBar';
import Hero from '../components/Hero';
import FadeEntry from '../components/FadeEntry';
import MetaData from '../components/MetaData';
import TextColumns from '../components/TextColumns';
import TextSections from '../components/TextSections';
import Quote from '../components/Quote';

const Academy = ({
  data: {
    allCourses,
    wordpressPage: {
      title,
      acf: {
        seo_title,
        seo_description,
        seo_images,
        hero: {
          hero_title,
          hero_text,
          hero_logo,
          hero_image,
          hero_image_mobile,
        },
        text_sections_,
        cta_title,
        cta_text,
        cta_link,
        cta_link_text,
        courses_title,
        courses,
        courses_text,
        testimonial_quote,
        testimonial_author,
        twitter_handle,
        linkedin_url,
        email,
      },
    },
  },
}) => {
  return (
    <Fragment>
      <MetaData
        title={seo_title || title}
        desciption={seo_description}
        images={seo_images}
      />

      <Hero
        title={hero_title}
        titleSize={"titleSm"}
        titleStyles={css`
          width: 300px;
        `}
        text={hero_text}
        logo={hero_logo}
        image={hero_image}
        imageMobile={hero_image_mobile}
        centered
      />

      <TextSections
        sections={text_sections_}
        compact={false}
        oddStyles={css`
          background: ${({ theme }) => theme.color.whiteish};
        `}
      />

      <TextColumns
        cards
        title={courses_title}
        columns={courses.map((id) => {
          const courseObj = allCourses.edges.find(
            ({ node }) => node.wordpress_id === id
          );

          return courseObj
            ? {
                title: courseObj.node.title,
                text: courseObj.node.excerpt,
                link: `/academy/${courseObj.node.slug}`,
                linkText: "Find out more",
              }
            : null;
        })}
      />

      <FadeEntry>
        <div
          dangerouslySetInnerHTML={{ __html: courses_text }}
          css={`
            position: relative;
            top: -45px;
            font-size: 20px;
            text-align: center;
          `}
        />
      </FadeEntry>

      <Quote quote={testimonial_quote} author={testimonial_author} />

      <CallToAction
        title={cta_title}
        text={cta_text}
        link={cta_link}
        linkText={cta_link_text}
      />

      <ContactBar
        twitter={twitter_handle}
        linkedin={linkedin_url}
        email={email}
      />
    </Fragment>
  );
};

export const pageQuery = graphql`
         {
           wordpressPage(slug: { eq: "academy" }) {
             title
             acf {
               seo_title
               seo_description
               seo_images {
                 image {
                   source_url
                 }
               }
               hero {
                 hero_title
                 hero_text
                 hero_image {
                   sizes {
                     large {
                       source_url
                       alt_text
                     }
                   }
                 }
                 hero_logo {
                   source_url
                   alt_text
                 }
                 hero_image_mobile {
                   sizes {
                     large {
                       source_url
                       alt_text
                     }
                   }
                 }
               }
               text_sections_ {
                 title
                 text
                 link_url {
                   source_url
                 }
                 image {
                   source_url
                   alt_text
                 }
                 link_text
               }
               courses_title
               courses
               courses_text
               testimonial_quote
               testimonial_author
               cta_title
               cta_text
               cta_link
               cta_link_text
               twitter_handle
               linkedin_url
               email
             }
           }
           allCourses: allWordpressWpBlCourses {
             edges {
               node {
                 wordpress_id
                 title
                 slug
                 excerpt
               }
             }
           }
         }
       `;

export default Academy;